import Main from "./components/main/Main"
import Sidebar from "./components/sidebar/Sidebar"
import Chatbox from "./components/Chatbox"


const App = () => {
  return (
    <>
    {/* <Chatbox/> */}
    <Sidebar/>
    <Main/>
    </>
  )
}

export default App
import { useContext } from "react";
import { assets } from "../../assets/assets";
import "./main.css";
import { Context } from "../../context/Context";

import LexAudio from "aws-lex2-audio";
import { LexRuntimeV2 } from "@aws-sdk/client-lex-runtime-v2";
import { Buffer } from "buffer";
import { useEffect, useRef, useState } from "react";
import * as AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import zlib from "react-zlib-js"; // TODO: Baklishan: zlib not compatible with react using alternative.
import "../../index.css";
import { IoIosSend } from "react-icons/io";
import { FaMicrophone, FaStopCircle, FaTimes } from "react-icons/fa";
import AudioVisualizer from "../../Chat/AudioVisualizer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  InputBase,
  TextField,
  Typography,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
// import audioControl from "aws-lex2-audio/lib/control";
const awsCredentials = {
  accessKeyId: import.meta.env.VITE_APP_ACCESS_KEY_ID,
  secretAccessKey: import.meta.env.VITE_APP_SECRET_ACCESS_KEY,
};
const lexRuntime = new LexRuntimeV2({
  credentials: awsCredentials,
  region: import.meta.env.VITE_APP_REGION,
});


const Main = () => {
	const {
		onSent,
		recentPrompt,
		showResults,
		loading,
		resultData,
		setInput,
		input,
	} = useContext(Context);
	
	const [messages, setMessages] = useState([]);
	const [audioUrl, setAudioUrl] = useState("");
	const [conversationStatus, setConversationStatus] = useState("");
	const [activeResponseIntent, setActiveResponseIntent] = useState("");
	// const [sessionId, setSessionId] = useState(""); // TODO: Balkishan: To manage Session for users.
	const [recording, setRecording] = useState(false);
	const [waitingReply, setWaitingReply] = useState(false);
	const [lexAudioControl, setLexAudioControl] = useState(null);
	const chatListRef = useRef(null);
	const [textInputValue, setTextInput] = useState("");
	const [conversation, setConversation] = useState(null);
  
	useEffect(() => {
	  scrollToBottom();
	}, [messages, lexAudioControl]);
  
	useEffect(() => {
	  try {
		setLexAudioControl(() => new LexAudio.audioControl());
		setConversation(
		  () =>
			new LexAudio.conversation(
			  {
				lexConfig: {
				  botAliasId: import.meta.env.VITE_APP_BOT_ALIAS,
				  botId: import.meta.env.VITE_APP_BOTID,
				  localeId: import.meta.env.VITE_APP_LOCAL_ID,
				  sessionId: "user-test-session",
				  lexRuntime,
				},
				silenceDetectionConfig: {
				  time: 2000,
				},
			  },
			  (state) => {
				console.log("message.textContent", state + "...");
				setConversationStatus((preState) => state);
				if (state === "Listening") {
				  setRecording(true);
				} else {
				  setRecording(false);
				}
  
				if (state === "Sending") {
				  setRecording(false);
				  setWaitingReply(true);
				}
			  },
			  async (data, arrayBufferData) => {
				console.log(
				  "Transcript: ",
				  decodeTranscript(data.interpretations),
				  ", Response: ",
				  data
				);
				const transcript = decodeTranscript(data.inputTranscript);
				const interpretations = decodeTranscript(data.interpretations);
				console.log(interpretations, transcript);
				// addMessage(transcript, "user");
				if (interpretations.length > 0) {
				  // addMessage( data.message ? decodeTranscript(data.messages) : "", "bot");
				  setActiveResponseIntent(() => interpretations[0].intent.name);
				  if (activeResponseIntent === "Stop") {
					lexAudioControl?.stopRecording();
					conversation?.reset();
				  }
				}
  
				let intentmessage;
				if (data.messages) {
				  intentmessage = decodeTranscript(data?.messages);
				}
				console.log("Intent Message: ", intentmessage);
				if (data && data.audioStream && transcript) {
				  setMessages((prevMessages) => [
					...prevMessages,
					{
					  sender: "user",
					  content: transcript ?? "",
					  type: "msg",
					},
				  ]);
				  scrollToBottom();
				  setTimeout(async () => {
					let audioBlobData = arrayBufferData
					  ? new Blob([arrayBufferData])
					  : null;
					setMessages((prevMessages) => [
					  ...prevMessages,
					  {
						sender: "Bot",
						content: intentmessage ? intentmessage[0]?.content : "",
						audioStream: audioBlobData
						  ? URL.createObjectURL(audioBlobData)
						  : "",
						type: "audio",
					  },
					]);
					scrollToBottom();
				  });
				}
			  },
			  (err) => {
				console.log(err);
			  },
			  (timeDomain, bufferLength) => {
				console.log(timeDomain, bufferLength);
			  }
			)
		);
	  } catch (err) {
		console.log(err);
	  }
	}, []);
  
	const playAudioFromUint8Array = (audioStream, contentType) => {
	  let audioBlob = new Blob([audioStream]);
	  return URL.createObjectURL(audioBlob);
	}
  
  
	const sendMessage = async (message) => {
	  if (message && message.length > 0) {
		try {
		 stopConversation();  
		  setMessages((prevMessages) => [
			...prevMessages,
			{ content: message, sender: "user", contentType: "PlainText" },
		  ]);
		  // scrollToBottom();
		  const params = {
			botAliasId: import.meta.env.VITE_APP_BOT_ALIAS,
			botId: import.meta.env.VITE_APP_BOTID,
			localeId: import.meta.env.VITE_APP_LOCAL_ID,
			sessionId: "unique-session-id",
			inputStream: message,
			requestContentType: "text/plain; charset=utf-8",
			responseContentType : "audio/mpeg"
		  };
		  lexRuntime.recognizeUtterance(params, async (err, response) =>{
			 console.log(response);
		  if (response.audioStream) {
			const audioBlobData = playAudioFromUint8Array(
			  await response.audioStream.transformToByteArray(),
			  "audio/mpeg"
			);
			let intentmessage;
			if (response.messages) {
			  intentmessage = decodeTranscript(response?.messages);
			}
			// const url = URL.createObjectURL(audioBlob);
			// setAudioUrl(url);
			setMessages((prevMessages) => [
			  ...prevMessages,
			  {
				content: response.messages
				  ? intentmessage[0].content
				  : "Error",
				sender: "bot",
				contentType: "audio/mpeg",
				type: "audio",
				audioStream: audioBlobData, autoPlay: true
			  },
			]);
		  } else {
			if (response.messages) {
			  setMessages((prevMessages) => [
				...prevMessages,
				{
				  content: response.messages
					? response.messages[0].content
					: "Error",
				  sender: "bot",
				  contentType: response.messages[0].contentType,
				},
			  ]);
			}
		  }
		  });
		 
		} catch (error) {
		  console.error("Error:", error);
		}
	  }
	};
  
	// const addMessage = (message, from) =>{
	//   setMessages(prevMessages => [
	//     ...prevMessages,
	//     { content: message, sender: from ? from : "user", contentType: "PlainText" }
	//   ]);
	// }
  
	const startRecordingWeb = () => {
	  if (
		conversationStatus === "Sending" ||
		conversationStatus === "Speaking" ||
		conversationStatus === "Listening" ||
		activeResponseIntent === "Stop"
	  ) {
		lexAudioControl?.stop();
		lexAudioControl?.stopRecording();
		// conversation?.reset();
	  } else {
		// console.log("Conversation Status: ", conversationStatus);
		if (conversation) {
		  conversation.advanceConversation();
		}
	  }
	};
  
	const stopPlaying = () => {
	  lexAudioControl.stop();
	};
  
	const decodeTranscript = (inputString) => {
	  const decodedTranscript = Buffer.from(inputString, "base64");
	  const decompressedTranscript = zlib.gunzipSync(decodedTranscript);
	  const transcriptString = decompressedTranscript.toString("utf-8");
	  const transcriptJSON = JSON.parse(transcriptString);
	  return transcriptJSON;
	};
  
	const scrollToBottom = () => {
	  try {
		chatListRef.current.scrollTo({
		  top: chatListRef.current.scrollHeight,
		  behavior: "smooth",
		});
	  } catch (err) {
		console.error("Error scrolling to bottom:", err);
	  }
	};
  
	const startProcedure = (message = "Start FAQ") => {
	  sendMessage(message);
	};
  
	const botStatusMsg = {
	  Passive: "Tap on Mic to Start.",
	  Listening: "Listening...",
	  Sending: "Waiting for reply...",
	  Speaking: "Speaking...",
	};
  
	const closeModal = () =>{
	  stopConversation();
	  onToggle();   
	}
  
	const stopConversation = () => {
	  if (
		conversationStatus === "Sending" ||
		conversationStatus === "Speaking" ||
		conversationStatus === "Listening" ||
		activeResponseIntent === "Stop"
	  ) {
		lexAudioControl?.stop();
		lexAudioControl?.stopRecording();
	  }
	}

    const handleCardClick = (promptText) => {
			setTextInput(promptText);
		};
	return (
		<div className="main">
			<div className="nav">
				<p>Lyra</p>
				<img src={assets.user} alt="" />
			</div>

			<div className="main-container">
				{messages.length === 0 ? (
					<>
						<div className="greet">
							<p>
								<span>Hello, I am Lyra. </span>
							</p>
							<p>How Can i Help You Today?</p>
						</div>
						<div className="cards">
							<div
								className="card"
								onClick={() =>
									handleCardClick("Hi")
								}
							>
								<p>Get Started</p>
								<img src={assets.compass_icon} alt="" />
							</div>
							<div
								className="card"
								onClick={() =>
									handleCardClick(
										"start scheduler"
									)
								}
							>
								<p>Try Scheduler</p>
								<img src={assets.message_icon} alt="" />
							</div>
							<div
								className="card"
								onClick={() =>
									handleCardClick("Start FAQ")
								}
							>
								<p>Try Frequently Asked Questions</p>
								<img src={assets.bulb_icon} alt="" />
							</div>
							<div
								className="card"
								onClick={() => {
									handleCardClick(
										"Thank you"
									);
								}}
							>
								<p>Thank you</p>
								<img src={assets.code_icon} alt="" />
							</div>
						</div>
					</>
				) : (
					<div className="result">
						<div className="result-title">
							{/* <img src={assets.user} alt="" /> */}
							<p>{recentPrompt}</p>
						</div>
						<div className="result-data">
							{/* <img src={assets.gemini_icon} alt="" /> */}
							{loading ? (
								<div className="loader">
									<hr />
									<hr />
									<hr />
								</div>
							) : (
								<p dangerouslySetInnerHTML={{ __html: resultData }}></p>
							)}
						</div>
					</div>
				)}


				<Box className="p-0" display="flex" alignItems="center" justifyContent="space-between">
				<div>
					<Typography
					variant="body2"
					className={`${recording ? 'text-green-500' : 'text-gray-500'} text-sm mb-2`}
					>
					{conversationStatus
						? botStatusMsg[conversationStatus]
						: ''}
					</Typography>
				</div>
				</Box>


				<div className="flex-1 overflow-auto" ref={chatListRef}>
        {!messages && (
          <Card className="m-4">
            <CardContent>
              <Typography
                variant="h5"
                component="div"
                fontWeight={700}
                className="start-text"
              >
                Welcome to New Age
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className="start-small-text"
              >
                Start by Saying "Hi", or Select an option below.
              </Typography>

              <Box mt={2}>
                <Button
                  variant="contained"
                  className="intro-btn"
                  onClick={() => startProcedure("Start FAQ")}
                  fullWidth
                >
                  FAQ
                </Button>
              </Box>
              <Box mt={2}>
                <Button
                  variant="contained"
                  className="intro-btn"
                  onClick={() => startProcedure("Start scheduler")}
                  fullWidth
                >
                  Scheduler
                </Button>
              </Box>
            </CardContent>
          </Card>
        )}
		

        {messages.map((message, index) => (
          <div
            key={index}
            className={`message-container ${
              message.sender === "user" ? "justify-end" : "justify-start"
            }`}
          >
            {message.sender !== "user" ? (
              <img src={assets.gemini_icon} className="h-12 mb-1 mr-2" alt="" />
            ) : (<></>
				// <div className="result-title">
				// 			<img src={assets.user} className="h-12 mb-1 ml-0" alt=""/>
				// 		</div>
					)}
            <div
              className={`message-bubble ${
                message.sender === "user" ? "user-bubble" : "bot-bubble"
              }`}
            >

              {message.content}
              {message.audioStream && (
                <div className="w-100">
                  <AudioVisualizer
                    audioUrl={message.audioStream}
                    autoplay={message.autoPlay ?? false}
                    link={message.audioStream}
                    onPlay={stopPlaying}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {audioUrl && (
        <div className="flex justify-center items-center mb-2">
          <AudioPlayer src={audioUrl} autoPlay />
        </div>
      )}

				<div className="main-bottom">
					<div className="search-box">

					<input
					fullWidth
					type="text"
					placeholder="Type your message here..."
					value={textInputValue}
					onChange={(e) => setTextInput(e.target.value)} 
					onKeyPress={(e) => {
						if (e.key === "Enter") {
						sendMessage(textInputValue);
						setTextInput(""); // Clear input after sending
						}
					}}
						/>

					<img src={assets.gallery_icon} alt="" />

					<img src={assets.mic_icon} alt=""
					disabled={["Listening", "Sending"].includes(conversationStatus)}
					onClick={startRecordingWeb}
					className={conversationStatus === "Listening" ? "animate-pulse" : ""}
					/>

					<img
						src={assets.send_icon}
						alt=""
						onClick={() => {
						sendMessage(textInputValue); // Pass input value to sendMessage
						setTextInput(""); // Clear input after sending
						}}
					/>
					</div>
					<div className="bottom-info">
						<p>
							AI may display inaccurate info, including about people, so
							double-check its responses. Copyright @2024 RevCorp.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Main;

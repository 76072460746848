window.global ||= window;
import audioControl from './control.js';
import conversation from './conversation.js';

/**
 * @module LexAudio
 * @description The global namespace for Amazon Lex Audio
 */
let LexAudio = {audioControl, conversation};

export default LexAudio;
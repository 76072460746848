import React, { useRef, useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { PlayCircleOutline, PauseCircleOutline } from '@mui/icons-material';
import WaveSurfer from 'wavesurfer.js';

const AudioVisualizer = ({ audioUrl, autoplay, onPlay }) => {
  const waveContainerRef = useRef(null);
  const waveSurferRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: waveContainerRef.current,
      waveColor: "#1da1f2",
      progressColor: "#b978d4",
      barWidth: 3,
      barGap: 4,
      barRadius: 50,
      height: 60,
      cursorWidth: 0,
      autoplay: autoplay ?? false,
      url: audioUrl,
    });
    waveSurferRef.current = waveSurfer;

    waveSurfer.on('play', () => {
      setIsPlaying(true);
    });

    waveSurfer.on('pause', () => {
      setIsPlaying(false);
    });

    return () => {
      if (waveSurferRef.current) {
        waveSurferRef.current.destroy();
        waveSurferRef.current = null;
      }
    };
  }, [audioUrl, autoplay]);

  const play = () => {
    onPlay();
    waveSurferRef.current.playPause();
  };

  const stop = () => {
    waveSurferRef.current.pause();
  };

  return (
    <div className='flex align-center'>
      <IconButton onClick={isPlaying ? stop : play}>
        {isPlaying ? <PauseCircleOutline style={{ color: '#0084ff', fontSize: 25 }} /> : <PlayCircleOutline style={{ color: '#0084ff', fontSize: 25 }} />}
      </IconButton>
      <div style={{ minWidth: "200px" }} className='audio' ref={waveContainerRef}></div>
    </div>
  );
};

export default AudioVisualizer;